<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <PageToolbar voltar title="Adicionar Empresa" icon="settings" dark />
      </v-col>

      <v-col cols="12">
        <v-card class="pa-4">
          <Campos add />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { one } from "@/services/api/tocs.api.js";

export default {
  name: "AddEmpresa",
  components: {
    Campos: () => import("./components/Campos"),
  },
};
</script>

<style></style>
